import React from 'react'

import { Link } from 'gatsby'
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image'

import Button from '@components/Content/Button/Button'
import Wysiwyg from '@components/Layout/Wysiwyg'

import { wpBlogCardProps } from '@contracts/post'

import * as styles from './BlogCard.module.scss'
import BlogPostMeta from './BlogPostMeta'

interface Props {
	data: wpBlogCardProps
}

const BlogCard = (props: Props) => {
	const { data } = props

	const category = data.categories.nodes[0]

	return (
		<div className={styles.card}>
			<div className={styles.imageContainer}>
				<Link to={`/blog/${data.slug}`}>
					{data.featuredImage !== null && data.featuredImage.node.localFile ? (
						<GatsbyImage
							image={data.featuredImage.node.localFile.childImageSharp.gatsbyImageData}
							className={styles.image}
							imgStyle={{ objectFit: 'contain' }}
							alt={data.title}
						/>
					) : (
						<StaticImage src="../../images/placeholder.jpg" alt="Coming soon!" className={styles.image} />
					)}
				</Link>
			</div>
			<div className={styles.contentContainer}>
				<BlogPostMeta category={category} date={data.date} />

				<div className={styles.content}>
					<h4 className={styles.title}>
						<Link to={`/blog/${data.slug}`}>{data.title}</Link>
					</h4>

					<Wysiwyg content={data.excerpt} className={styles.excerpt} />
				</div>

				<div className={styles.actions}>
					<Button label="Read" uri={`/blog/${data.slug}`} />
				</div>
			</div>
		</div>
	)
}

export default BlogCard
