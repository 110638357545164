import { graphql, useStaticQuery } from 'gatsby'

interface dataProps {
	allWpPost: {
		nodes: [
			{
				id: string
				slug: string
				uri: string
				title: string
			}
		]
	}
}

const useStickyPosts = () => {
	const data: dataProps = useStaticQuery(graphql`
		query AllStickyPostSlugs {
			allWpPost(sort: { fields: date }, filter: { isSticky: { eq: true } }) {
				nodes {
					id
					slug
					uri
					title
				}
			}
		}
	`)

	return data.allWpPost.nodes
}

export default useStickyPosts
