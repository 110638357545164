import React from 'react'

import Button from '@components/Content/Button/Button'

import { wpBlogCardGridProps } from '@contracts/post'

import BlogCard from './BlogCard'
import * as styles from './BlogCardGrid.module.scss'

const BlogCardGrid = (props: wpBlogCardGridProps) => {
	const gridStyles = [styles.grid]

	if (props.type == 'in-grid') {
		gridStyles.push(styles.inGrid)
	}

	return (
		<div className={styles.container}>
			<ul className={gridStyles.join(' ')}>
				{props.posts.map((post) => {
					return (
						<li key={post.id}>
							<BlogCard data={post} />
						</li>
					)
				})}
			</ul>
			{props.hasNextPage ? (
				<Button uri={props.nextPageLink} label={'Show More'} className={styles.button} />
			) : null}
		</div>
	)
}

BlogCardGrid.defaultProps = {
	type: 'default',
}

export default BlogCardGrid
