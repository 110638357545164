import moment from 'moment'

import React from 'react'

import { HeadProps, graphql } from 'gatsby'

import BlogCardGrid from '@components/Blog/BlogCardGrid'
import BlogSidebar from '@components/Blog/BlogSidebar'
import Section from '@components/Content/Section/Section'
import GatsbyHead from '@components/Layout/GatsbyHead'
import Layout from '@components/Layout/Layout'
import SEO from '@components/Layout/SEO'
import SidebarSection from '@components/Layout/SidebarSection'
import Wysiwyg from '@components/Layout/Wysiwyg'

import { wpPageSeoProps } from '@contracts/page'
import { wpBlogCardProps } from '@contracts/post'

import useStickyPosts from '../hooks/useStickyPosts'
import * as styles from './BlogArchive.module.scss'

interface Props {
	pageContext: {
		posts: wpBlogCardProps[]
		categories: [{ name: string; slug: string; uri: string }]
		hasNextPage: boolean
		nextPageLink: string
		datesWithPosts: Array<string>
	}
	data: {
		allWpPost: {
			nodes: wpBlogCardProps[]
		}
		wpPage: {
			title: string
			content: string
			seo: wpPageSeoProps
		}
	}
}

const BlogArchive = (props: Props) =>
{
	const posts = props.data.allWpPost.nodes
	const blogPage = props.data.wpPage

	const stickyPosts = useStickyPosts()

	// Need to transform the object here to match the format of the sidebar links
	const stickyPostsLinks = stickyPosts.map((post) => {
		return {
			name: post.title,
			slug: post.slug,
			uri: post.uri,
		}
	})

	// Need to transform the object here to match the format of the sidebar links
	const dateLinks = props.pageContext.datesWithPosts.map((date) => {
		return {
			name: moment(date, 'YYYY/M').format('MMMM YYYY'),
			slug: date,
			uri: `/blog/${date}`,
		}
	})

	let title = blogPage.title
	let content = blogPage.content
	if(props.pageContext.filter && props.pageContext.filter.categories) {
		title = posts[0].categories.nodes[0].name
		content = posts[0].categories.nodes[0].description
	}

	const main = (
		<Section type="wide" className={styles.main} containerClassName={styles.container} noPadding>
			<BlogCardGrid
				posts={posts}
				type="in-grid"
				hasNextPage={props.pageContext.hasNextPage}
				nextPageLink={props.pageContext.nextPageLink}
			/>
		</Section>
	)

	const sidebar = (
		<BlogSidebar
			categories={props.pageContext.categories}
			stickyPostsLinks={stickyPostsLinks}
			dateLinks={dateLinks}
		/>
	)

	return (
		<Layout className={styles.layout}>
			<Section className={styles.intro} noPaddingBottom>
				<h1 className={styles.title}>{title}</h1>

				<Wysiwyg className={styles.subcontent} content={content} />
			</Section>

			<SidebarSection sidebar={sidebar} main={main} mobileToggle={true} />
		</Layout>
	)
}

export const query = graphql`
	query BlogPage($limit: Int, $filter: WpPostFilterInput = null) {
		allWpPost(sort: { fields: date }, limit: $limit, filter: $filter) {
			pageInfo {
				hasNextPage
			}
			nodes {
				title
				content
				excerpt
				uri
				slug
				id
				date(formatString: "YYYY/MM/DD")
				featuredImage {
					node {
						localFile {
							childImageSharp {
								gatsbyImageData(width: 550, formats: JPG, placeholder: BLURRED, aspectRatio: 1.5)
							}
						}
					}
				}
				categories {
					nodes {
						uri
						name
						description
						postCategoryOptions {
							icon {
								localFile {
									url
								}
							}
						}
						seo {
							canonical
							metaDesc
							metaKeywords
							metaRobotsNoindex
							metaRobotsNofollow
							opengraphAuthor
							opengraphDescription
							opengraphModifiedTime
							opengraphPublishedTime
							opengraphPublisher
							opengraphSiteName
							opengraphTitle
							opengraphType
							opengraphUrl
							title
							twitterTitle
							schema {
								raw
							}
							twitterDescription
							twitterImage {
								localFile {
									childImageSharp {
										gatsbyImageData(
											layout: CONSTRAINED
											placeholder: BLURRED
											quality: 100
											webpOptions: { quality: 100 }
											pngOptions: { quality: 100 }
											jpgOptions: { quality: 100 }
										)
									}
								}
							}
						}
					}
				}
			}
		}
		allWpCategory(filter: { name: { ne: "Uncategorised" } }) {
			nodes {
				slug
				name
				uri
			}
		}
		wpPage(isPostsPage: { eq: true }) {
			title
			content
			seo {
				canonical
				metaDesc
				metaKeywords
				metaRobotsNoindex
				metaRobotsNofollow
				opengraphAuthor
				opengraphDescription
				opengraphModifiedTime
				opengraphPublishedTime
				opengraphPublisher
				opengraphSiteName
				opengraphTitle
				opengraphType
				opengraphUrl
				readingTime
				title
				twitterTitle
				schema {
					articleType
					pageType
					raw
				}
				twitterDescription
				twitterImage {
					localFile {
						childImageSharp {
							gatsbyImageData(
								layout: CONSTRAINED
								placeholder: BLURRED
								quality: 100
								webpOptions: { quality: 100 }
								pngOptions: { quality: 100 }
								jpgOptions: { quality: 100 }
							)
						}
					}
				}
			}
		}
	}
`

export default BlogArchive

export function Head({ data, pageContext }: HeadProps<Queries.BlogPageQuery>) {
	let seo = data.wpPage?.seo
	if(pageContext.filter && pageContext.filter.categories) {
		seo = data.allWpPost?.nodes[0].categories?.nodes[0].seo
	}
	return (
		<>
			<GatsbyHead />
			<SEO data={seo} />
		</>
	)
}
